<template>
    <div>

    </div>
</template>

<script>
export default {
    name: 'robots',
    mounted() {
        window.open(`@public/robots.txt`);
    }
}
</script>